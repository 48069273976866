import $ from 'jquery'

$(function () {
  AOS.init()

  const menuIcon = document.getElementById('menuIcon')
  const mobileMenu = document.getElementById('mobileMenu')
  const closeMenu = document.getElementById('closeMenu')
  const webdoorItems = document.querySelectorAll('.webdoor-items')

  menuIcon.addEventListener('click', function () {
    mobileMenu.classList.remove('d-none')
    document.body.classList.add('no-scroll')

    webdoorItems.forEach((item) => {
      item.classList.add('d-none')
    })
  })

  closeMenu.addEventListener('click', function () {
    mobileMenu.classList.add('d-none')
    document.body.classList.remove('no-scroll')

    webdoorItems.forEach((item) => {
      item.classList.remove('d-none')
    })
  })

  const menuLinks = document.querySelectorAll('#mobileMenu a[href^="#"]')
  menuLinks.forEach((link) => {
    link.addEventListener('click', (event) => {
      event.preventDefault()
      const targetId = link.getAttribute('href')
      const targetElement = document.querySelector(targetId)

      mobileMenu.classList.add('d-none')
      document.body.classList.remove('no-scroll')

      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })

      webdoorItems.forEach((item) => {
        item.classList.remove('d-none')
      })
    })
  })
})
